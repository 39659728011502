import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';
import { Row, Col, Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';



function Show() {

  const [lista1, setLista] = useState([]);

  /* const [error, setError] = useState ( [] );

   const errorsCollection = collection(db, "Errors");

   const getErrors = async () => {
       const data = await getDocs(errorsCollection);
       console.log(data.docs);

       /*setError(
           data.docs.map((doc) => ({...doc.data(), id:doc.id}))
       )

       console.log(error);
   }*/

  useEffect(() => {
    const getLista = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Errors'))
        const docs = []

        querySnapshot.forEach((doc) => {
          const body = JSON.parse(doc.data().options['body'])
          const timestamp = parseInt(doc.data().id, 10);

              const date = new Date(timestamp);

              const yyyy = date.getFullYear();
              let mm = date.getMonth() + 1; // Months start at 0!
              let dd = date.getDate();

              if (dd < 10) dd = '0' + dd;
              if (mm < 10) mm = '0' + mm;

              const formattedToday = dd + '/' + mm + '/' + yyyy;

          docs.unshift({ ...doc.data(), id: doc.id, body: body, date: formattedToday })

        })
        console.log(docs)
        setLista(docs);


      } catch (error) {
        console.log(error);
      }
    }
    getLista()
  }, [lista1]);



  // Funcion para eliminar

  const deleteDatos = async (id) => {
    await deleteDoc(doc(db, 'Errors', id))
  }



  return (

    <>
      <div className="container"><h1>Errors Leads</h1></div>
      

      {
        lista1.map(list => (
          <Container key={list.id}>
            <Row>
              <Col lg={10}>
                <Accordion defaultActiveKey="0" className="mb-3 ">
                  <Accordion.Item eventKey={list.id} >
                    <Accordion.Header >
                      <Row>
                        <Row>
                          <Col lg={4}><strong>{list.id} | {list.date}</strong></Col>
                          <Col lg={4}>{list.loc}</Col>
                          <Col lg={4}>{list.error}</Col>
                        </Row>
                        <Row>
                          <Col lg={4}>{list.body.First_Name__c} </Col>
                          <Col lg={4}>{list.body.Creation_DateTime__c}</Col>
                        </Row>
                      </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col lg={6} >
                          <Row>
                            <Col lg={3} className="text-start"><strong>FirstName</strong></Col>
                            <Col lg={4} className="text-start">{list.body.First_Name__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>LastName</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Last_Name__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Email</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Email__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Nº </strong></Col>
                            <Col lg={4} className="text-start">{list.body.Numero_Documento__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Prefijo</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Prefijo__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Nacionalidad</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Nacionalidad__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Birthdate</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Birthdate__c}</Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Telefono</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Mobile_Phone__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Tipo Documento</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Tipo_Documento__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Portfolio</strong></Col>
                            <Col lg={4} className="text-start">{list.body.Portfolio__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Statement</strong></Col>
                            <Col className="text-start">{list.body.Statement__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Videopich</strong></Col>
                            <Col className="text-start">{list.body.Videopich__c}</Col>
                          </Row>
                          <Row>
                            <Col lg={3} className="text-start"><strong>Curriculum</strong></Col>
                            <Col className="text-start">{list.body.Curriculum__c}</Col>
                          </Row>

                        </Col>

                      </Row>

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col lg={2} className="text-start"><button className="btn btn-danger " onClick={() => deleteDatos(list.id)}>-</button></Col>
            </Row>
          </Container>

        ))
      }


    </>
  )
}

export default Show