import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './components/Login';
import Home from './components/Home';
import Upload from './components/Upload';
import Upload2 from './components/Upload2';
import Upload3 from './components/Upload3';
import GetInfo from './components/GetInfo';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';


function App() {

  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/upload2" element={<Upload2 />} />
            <Route path="/upload3" element={<Upload3 />} />
            <Route path="/getinfo" element={<GetInfo />} />
            <Route element={
              <div><h1>Página no encontrado 404</h1></div>
            } />
          </Routes>
        </div>
      </Router>


    </>

  );
}

export default App;
