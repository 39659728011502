import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth } from '../firebaseConfig/firebase';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/")
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    const handleMicrosoftLogin = (e) => {
        e.preventDefault();
        //setLoader(prevState => ({ ...prevState, microsoftLoading: true }));
        const provider = new OAuthProvider('microsoft.com').setCustomParameters({
            tenant: 'escaces.onmicrosoft.com'
        });

        signInWithPopup(auth, provider)
            .then(data => {
                console.log(data);

                navigate("/")
                //setLoader(prevState => ({ ...prevState, microsoftLoading: false }));
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    }



    return (
        <>
            <main >
                <section>
                    <div className="container">

                        <form className="m-5">
                            <div className="mb-3">
                                <label htmlFor="email-address" className="form-label">
                                    Email address
                                </label>
                                <input
                                    className="form-control"
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">
                                    Password
                                </label>
                                <input
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <button className="btn btn-primary"
                                    onClick={onLogin}
                                >
                                    Login
                                </button>
                            </div>

                            <button className="btn btn-secondary"
                                onClick={handleMicrosoftLogin}
                            >
                                Login Microsoft
                            </button>
                        </form>

                    </div>
                </section>
            </main>
        </>
    )
}

export default Login